<template>
	<section class="about">
		<template v-if="$root.rwd < 2">
			<lottie :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
		</template>
		<div class="max-width-1000">
			<br>
			<br>
			<quote-title label="關於金枝" />
			<br>
			<br>
			<template v-if="$root.rwd < 3">
				<template v-for="({url, name}, i) in _about.banners">
					<img :src="url" width="100%" :alt="name" :key="i">
				</template>
			</template>
			<template v-else>
				<template v-for="({url, name}, i) in _about.banners_mobile">
					<img :src="url" width="100%" :alt="name" :key="i">
				</template>
			</template>
			<br>
			<article v-html="_about.article" />
		</div>
		<br>
		<br>
		<br>
		<br>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
	computed: {
		...mapState("about", ["_about"]),
	},
	methods: {
		...mapActions("about", ["_getData"]),
	},
	async created() {
		await this._getData("about");
	}
}
</script>

<style lang="scss" scoped>
.about {
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush1.png"),
		url("../../../assets/background/dot1.png");
	background-position: center bottom, center 80%;
	padding: 0;

	@media screen and (min-width: 768px) {
		padding: 0 5%;
	}

	.lottie {
		position: absolute;
		width: 25%;
		max-width: 220px;
		bottom: 25%;
		right: 20px;
	}
}
</style>